<template>
<div
  class="dropdown h-7 2xl:h-9 rounded-lg 2xl:rounded-xl text-sm"
  :class="{
    'dropdown--menu': menu,
    'dropdown--searching': isSearchOffset,
  }"
  :style="{
    ...cssVars, width: width || 'auto',
    maxWidth: maxWidth || 'unset',
    minWidth: minWidth || 'unset',
  }">
  <VueMultiselect
    :options="options"
    :multiple="multiple"
    :searchable="searchable"
    :close-on-select="!multiple"
    :placeholder="placeholder"
    v-model="calculatedValue"
    @select="$emit('select', $event)"
    @search-change="$emit('search', $event)"
    @input="changeValue"
    @open="opened"
    @close="closed"
    :disabled="isDisabled"
    :loading="loading"
    v-bind="{...computedConfig, ...$attrs}"
    ref="multiselect"
  >
    <template #caret="{ toggle }">
      <div
        class="caret caret--rotable right-2 2xl:right-1.5"
        :class="isDisabled ? 'tw-hidden' : ''"
        @mousedown.prevent.stop="toggle()"
      >
        <slot name="caret">
          <dropdownIcon/>
        </slot>
      </div>
    </template>
    <template slot="singleLabel" slot-scope="props">
      <div class="custom-label custom-label--singleLabel">
        <slot name="before"/>
        <span class="custom-label__text">
            {{ getLabel(props.option) }}
        </span>
        <slot name="after"/>
      </div>
    </template>
    <template slot="placeholder">
      <span class="custom-label custom-label--placeholder">
        <slot name="before"/>
        <span
          class="custom-label__text"
          :class="{ 'opacity': !menu }"
        >{{ placeholder }}</span>
        <slot name="after"/>
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <slot name="option" :option="props.option">
        <div class="custom-label custom-label--option" :title="getLabel(props.option)">
          <component
            v-if="props.option.icon"
            :is="props.option.icon"
            class="custom-label__icon"
          />
          <span class="custom-label__text">
              {{ getLabel(props.option) }}
          </span>
        </div>
      </slot>
    </template>
    <template slot="tag" slot-scope="props">
      <div class="custom-tag h-5 text-xs 2xl:h-6 2xl:text-sm">
        {{ getLabel(props.option) }}
        <button
          class="custom-tag__icon"
          title="Remove"
          type="button"
          @click.stop.prevent="props.remove(props.option)"
        >
          <cross-icon />
        </button>
      </div>
    </template>
    <template v-if="loading" slot="noResult">
      Loading...
    </template>
  </VueMultiselect>
</div>
</template>

<script>
// TODO v-model

import VueMultiselect from "@/components/vue-multiselect";
import dropdownIcon from "@/assets/icons/dashboard/dropdown.svg";
import crossIcon from "@/assets/icons/dashboard/cross.svg";

export default {
  name: "dropdown",
  components: {
    VueMultiselect,
    dropdownIcon,
    crossIcon,
  },
  props: {
    value: [Object, Array, String, Number],
    menu: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Object,
      default: () => ({}),
    },
    optionIcons: {
      type: Object,
      default: () => ({}),
    },
    disabled: Boolean,
    placeholder: String,
    width: String,
    maxWidth: String,
    minWidth: String,
    searchable: {
      type: Boolean,
      default: false
    },
    reposition: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      calculatedValue: null,
      isOpen: false,
    };
  },
  // created() {
  //   this.calculatedValue = this.initialValue;
  // },
  computed: {
    isDisabled() {
      // return !this.options.length;
      return this.disabled;
    },
    cssVars() {
      return {
        '--color-text': this.colors.text || '#fff',
        '--color-bg': this.colors.bg || '#029ADB',
        '--color-border': this.colors.border || '#029ADB',
        '--color-svg': this.colors.svg || this.colors.text || '#fff',
      }
    },
    computedConfig() {
      return {
        searchable: false,
        limit: 2,
        selectLabel: '',
        deselectLabel: '',
        ...this.config,
      }
    },
    isSearchOffset() {
      return this.isOpen && this.searchable && this.calculatedValue?.length && this.multiple;
    },
  },
  created() {
      document.addEventListener("scroll", this.placeDropdown, true);
      window.addEventListener("resize", this.placeDropdown);
  },
  mounted() {
      this.$nextTick(() => {
          this.placeDropdown();
          this.calculatedValue = this.value;
      });
  },
  destroyed() {
      document.removeEventListener("scroll", this.placeDropdown, true);
      window.removeEventListener("resize", this.placeDropdown);
  },
  watch: {
    calculatedValue() {
      this.placeDropdown();
    },
    value(value) {
      // console.log('watch value', value);
      this.calculatedValue = value;
      // this.changeValue(value);
    },
  },
  methods: {
    changeValue(calculatedValue) {
      if (this.menu) this.calculatedValue = null;
      this.$emit('input', calculatedValue);
      if (this.multiple) {
      const selectedOptions = Array.isArray(calculatedValue) ? calculatedValue : [calculatedValue];
      const deselectedOptions = this.options.filter(option => !selectedOptions.includes(option));

      this.$emit('option-selected', selectedOptions);
      this.$emit('option-deselected', deselectedOptions);
    }
    },
    getLabel(prop) {
      return prop[this.config.label || 'title'] || prop;
    },
    closed() {
      this.isOpen = false;
    },
    opened() {
      this.isOpen = true;
      this.placeDropdown();
    },
    placeDropdown() {
      if (this.reposition && this.isOpen) {
        this.$nextTick(() => {
          const {top, height, width, left} = this.$el.getBoundingClientRect();
          const ref = this.$refs.multiselect;
          if (ref) {
            const searchInput = ref.$el.querySelector('.multiselect__input');
            // ref.$refs.list.style.width = `${width}px`;
            ref.$refs.list.style.width = `fit-content`;
            ref.$refs.list.style.minWidth = `${width}px`;
            ref.$refs.list.style.position = 'fixed';
            ref.$refs.list.style.bottom = 'auto';
            const addOffset = this.isSearchOffset ? 36 : 0;
            if (addOffset) {
              searchInput.style.width = '100%';
              searchInput.classList.add('h-9');
              searchInput.setAttribute('placeholder', this.placeholder);
            } else {
              searchInput.classList.remove('h-9');
            }
            ref.$refs.list.style.top = `${top + height + addOffset}px`;
            ref.$refs.list.style.left = `${left}px`;
          }
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/mixins/mixins.scss";
@import '@/assets/styles/functions.scss';

.dropdown {
  $text: var(--color-text);
  $bg: var(--color-bg);
  $border: var(--color-border);
  $svg: var(--color-svg);
  @include buttonBase($text: $text, $border: $border, $bg: $bg, $svg: $svg, $responsive: true);

  position: relative;
  display: flex;
  align-items: stretch;
  min-width: 114px;
  padding: 0;
  .caret {
    display: inline-flex;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    // right: 14px;
    padding: 5px 1px;
    z-index: 1;
    &.tw-hidden {
      display: none;
    }
    svg {
      // width: 12px;
      transition: transform 0.2s;
      // fill: $caret;
      // path {
      //   fill: $caret;
      // }
    }
  }
  ::v-deep {
    .multiselect {
      outline: none;
      width: 100%;
      display: flex;
      min-height: unset;
      font-size: toRem(14px);
      &--active {
        .caret {
          &--rotable {
            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }
    .multiselect__content-wrapper {
      @include scrollBar;
      border-radius: 0 0 16px 16px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
      top: 102%;
      max-width: 100%;
      // width: auto;
      width: 100%;
      right: 0;
      height: min-content;
      // min-width: 160px;
    }
    .multiselect__content {
      width: 100%;
    }
    .multiselect__tags {
      background: none;
      border: none;
      min-height: 24px;
      width: 100%;
      box-sizing: border-box;
      padding: 0 32px 0 14px;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      &-wrap {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        @apply gap-1 2xl:gap-3;
        width: 100%;
        overflow-x: auto;
        @include scrollBar(8px, 4px);
        .custom-tag {
          padding: 0 28px 0 12px;
          border-radius: 8px;
          display: inline-flex;
          white-space: nowrap;
          align-items: center;
          // font-size: toRem(14px);
          font-weight: 600;
          position: relative;
          background-color: #D9D9D9;
          // height: 24px;
          &__icon {
            position: absolute;
            right: 7px;
            transform: translateY(-50%);
            top: 50%;
            cursor: pointer
          }
        }
      }
      .multiselect__strong {
        margin-bottom: 0;
      }
    }
    .multiselect__single {
      background: none;
      margin: 0;
      padding: 0;
      text-align: center;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .multiselect__single, .multiselect__placeholder, .multiselect__option {
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;
      padding: 0;
      // custom label template:
      .custom-label {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        svg {
          flex-shrink: 0;
          @apply w-5 h-5;
        }
        &__text {
          overflow: hidden;
          text-overflow: ellipsis;
          flex-grow: 1;
          text-align: left;
          color: $text; // TODO
          &.opacity {
            opacity: .8;
          }
        }
      }
    }
    .multiselect__single, .multiselect__placeholder {
      .custom-label {
        &__text {
          color: $text;
        }
      }
    }
    .multiselect__option {
      .custom-label {
        &__text {
          color: #282828;
        }
      }
    }
    .multiselect__select {
      z-index: 1;
    }
    .multiselect__element {
      &:not(:first-child) {
        .multiselect__option {
          border-top: 1px solid #A8A8A8;
        }
      }
    }
    .multiselect__option {
      font-size: toRem(14px);
      min-height: toRem(18px);
      box-sizing: border-box;
      padding: toRem(12px) toRem(20px) toRem(12px) toRem(16px);
      color: #282828;
      font-weight: 500;
      // .custom-label {
      //   svg {
      //       fill: #282828;
      //       path {
      //           fill: #282828;
      //       }
      //   }
      // }
      &--selected {
        background-color: #cce3ff96!important;
      }
      &--highlight {
        background-color: #CCE3FF!important;
        padding-right: toRem(16px);
        border-right: 4px solid #3B2CD4;
      }
    }
    .multiselect__placeholder {
      width: 100%;
      margin: 0;
      color: $text;
    }
    .multiselect__input {
      margin-bottom: 0;
      padding: 0;
    }
    .multiselect__spinner {
      &::before, &::after {
        border-color: #0D69D5 transparent transparent;
      }
    }
  }
  &--searching {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    ::v-deep {
      .multiselect__input {
        @apply px-3;
        top: 100%;
        left: 0;
        width: 100%;
        position: absolute;
        // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4)!important;
        border-radius: 0;
      }
    }
  }
}
</style>